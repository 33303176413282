// src/components/GeneralSettings.js
import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
  useTheme,
  CircularProgress
} from "@mui/material";

import { useServer } from "../context/ServerContext";
import { useServerSettings } from "../context/ServerSettingsContext";
import RealmData from "../data/realms.json";

const GeneralSettings = () => {
  const theme = useTheme();
  const { serverChannels } = useServer();
  const {
    region,
    setRegion,
    realm,
    setRealm,
    welcomeMode,
    setWelcomeMode,
    welcomeChannel,
    setWelcomeChannel,
    welcomeMessage,
    setWelcomeMessage,
    saveGeneralSettings,
    generalSettingsLoading
  } = useServerSettings();

  const textChannels = serverChannels.filter((channel) => channel.type === 0);

  const handleSave = () => {
    saveGeneralSettings();
  };

  if (generalSettingsLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h4" align="left">
        Settings
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="region"
                sx={{
                  backgroundColor: "background.default", // Match the background color
                  paddingLeft: 1,
                  paddingRight: 1,
                  "&.Mui-focused": {
                    backgroundColor: "background.default", // Match the background color when focused
                  },
                }}
              >
                Region
              </InputLabel>
              <Select
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              >
                <MenuItem value="us">US</MenuItem>
                <MenuItem value="eu">EU</MenuItem>
                <MenuItem value="tw">TW</MenuItem>
                <MenuItem value="kr">KR</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="realm"
                sx={{
                  backgroundColor: "background.default", // Match the background color
                  paddingLeft: 1,
                  paddingRight: 1,
                  "&.Mui-focused": {
                    backgroundColor: "background.default", // Match the background color when focused
                  },
                }}
              >
                Realm
              </InputLabel>
              <Select value={realm} onChange={(e) => setRealm(e.target.value)}>
                {RealmData.map(
                  (realm) =>
                    realm.region === region && (
                      <MenuItem key={realm.name} value={realm.slug}>
                        {realm.name}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Send Welcome Messages to new users</Typography>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="welcome-mode"
                  sx={{
                    backgroundColor: "background.default", // Match the background color
                    paddingLeft: 1,
                    paddingRight: 1,
                    "&.Mui-focused": {
                      backgroundColor: "background.default", // Match the background color when focused
                    },
                  }}
                >
                  Welcome Mode
                </InputLabel>
                <Select
                  value={welcomeMode}
                  onChange={(e) => setWelcomeMode(e.target.value)}
                >
                  <MenuItem value="0">Disabled</MenuItem>
                  <MenuItem value="1">DM</MenuItem>
                  <MenuItem value="2">Channel</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {welcomeMode == 2 && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Welcome Channel</InputLabel>
                  <Select
                    value={welcomeChannel}
                    onChange={(e) => setWelcomeChannel(e.target.value)}
                  >
                    {textChannels.map((channel) => (
                      <MenuItem key={channel.id} value={channel.id}>
                        #{channel.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextareaAutosize
            minRows={5}
            maxLength={1500}
            placeholder="Enter your welcome message here"
            value={welcomeMessage || ""}
            onChange={(e) => setWelcomeMessage(e.target.value)}
            style={{
              width: "100%",
              backgroundColor: theme.palette.background.default, // Match the background color
              color: theme.palette.text.primary,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="success"
          fullWidth
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
